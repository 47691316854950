<template>
  <div class="page-header-ft">
    <span v-if="!userInfo.id" class="ait-btn-small login-btn" @click="toLogin"
      >免费注册 | 登录</span
    >
    <el-dropdown v-else @command="handleCommand">
      <span class="el-dropdown-link">
        <el-avatar
          class="user-avatar"
          fit="cover"
          :src="userInfo.avatar_url || url"
        ></el-avatar>
        <span class="user-name">{{ userInfo.nickname || userInfo.phone }}</span>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          icon="el-icon-video-camera"
          command="learncenter"
          style="width: 115px"
          >学习中心</el-dropdown-item
        >
        <el-dropdown-item icon="el-icon-setting" command="setting"
          >账号设置</el-dropdown-item
        >
        <el-dropdown-item icon="el-icon-video-pause" command="logout"
          >退出</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
export default {
  name: "BasicLayoutHeaderUserInfo",
  data() {
    return {
      url: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    toLogin() {
      window.location.href = `https://account.aitschool.com?referer=${window.location.href}`;
    },
    handleCommand(command) {
      this[`handle${command}`]();
    },
    handlelogout() {
      Cookies.remove("web_token", {
        path: "/",
        domain: `${process.env.VUE_APP_DOMAIN}`
      });
      this.$store.commit("USERINFO", {});
      //window.location.href = "/";
    },
    handlesetting() {
      this.$router.push({ path: "/setting/my" });
    },
    handlelearncenter() {
      this.$router.push({ path: "/studyHistory/my/course" });
    }
  }
};
</script>
<style type="text/css" lang="less">
.page-header-ft {
  display: flex;
  align-items: center;
  .el-dropdown {
    cursor: pointer;
    &:hover {
      background-color: #ebf8ee;
    }
    .el-dropdown-link {
      display: block;
      padding: 3px 15px;
      height: 100%;
      .user-avatar {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        vertical-align: middle;
      }
      .user-name {
        font-size: 12px;
        color: #1e1e1e;
        font-weight: 400;
        vertical-align: sub;
      }
    }
  }
}
</style>
