<template>
  <div class="evaluation-page">
    <div class="card-header">
      <span class="header-title">技能</span>
      <span class="header-description">技能测评，了解你的强弱项</span>
    </div>
    <div class="card-list">
      <span class="card-item" v-for="item in evaluationList" :key="item.id">
        <span class="img-container"><img :src="item.image_url"/></span>
        <span class="stack">
          <span class="stack-name">{{ item.name }}</span>
          <span class="stack-self-rating">会这个技能？来认证吧</span>
        </span>
        <el-button type="primary" @click="handleOpen(item)"
          >开始答题<i class="arrow-right">
            <svg
              t="1618992283048"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="9551"
              width="16"
              height="16"
            >
              <path
                d="M576 502.4l-272 272 92.8 89.6 268.8-272 92.8-89.6-92.8-89.6-268.8-272-92.8 89.6z"
                p-id="9552"
                fill="#ffffff"
              ></path>
            </svg> </i
        ></el-button>
        <Dialog
          :visible="dialogVisible"
          @dialogClose="Close_dialog"
          :item="current_item"
        ></Dialog>
      </span>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="pagination.total"
      :page-size="page_size"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import evaluation from "@/global/services/evaluation.js";
import Dialog from "./widgets/Dialog";
export default {
  components: {
    Dialog
  },
  name: "Exam",
  data() {
    return {
      page_size: 18,
      current_page: 1,
      evaluationList: "",
      pagination: "",
      dialogVisible: false,
      current_item: {}
    };
  },
  created() {
    this.getStackList();
  },
  methods: {
    getStackList() {
      let page_size = this.page_size;
      let page = this.current_page;
      evaluation.getEvaluationList({ page_size, page }).then(res => {
        this.evaluationList = res.data.data.list;
        this.pagination = res.data.data.pagination;
      });
    },
    changePage(current_page) {
      this.current_page = current_page;
      this.getStackList();
    },
    handleOpen(item) {
      this.dialogVisible = true;
      this.current_item = item;
    },
    Close_dialog() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.evaluation-page {
  width: 100%;
  padding: 0 calc(50% - 620px);
  .card-header {
    margin: 20px 0;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    .header-title {
      font-size: 1.625rem;
      line-height: 1.625rem;
      font-weight: 700;
      color: #333;
      margin-right: 10px;
    }
    .header-description {
      font-size: 0.875rem;
      font-weight: 400;
      color: #666;
    }
  }
  .card-list {
    display: flex;
    flex-wrap: wrap;
    .card-item {
      display: flex;
      align-items: center;
      width: 400px;
      height: 110px;
      border: 1px solid #ebebeb;
      background: #fff;
      margin: 0 10px 22px;
      padding: 22px 19px;
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 3px 5px #ddd;
      }
      .img-container {
        display: inline-block;
        width: 60px;
        height: 70px;
        object-fit: contain;
        margin-right: 25px;
        img {
          max-width: 100%;
        }
      }
      .stack {
        flex: 1;
        display: flex;
        flex-direction: column;
        .stack-name {
          font-size: 18px;
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 500;
          color: #333;
          line-height: 26px;
        }
        .stack-self-rating {
          font-size: 16px;
          font-weight: 400;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          line-height: 24px;
          color: #999;
          margin-top: 10px;
        }
      }
      /deep/.el-button {
        width: 90px;
        height: 32px;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        border-radius: 0;
        padding: 0 0 0 7px;
        outline: none;
        text-align: center;
        span {
          line-height: 32px;
          position: relative;
          .arrow-right {
            line-height: 16px;
            vertical-align: text-top;
          }
        }
      }
      /deep/.el-dialog {
        .el-dialog__header {
          padding: 50px 80px 6px;
          height: fit-content;
          .dialog-header {
            display: flex;
            img {
              width: 88px;
              height: 88px;
              margin-right: 6px;
              border-radius: 50%;
              border: 1px solid hsla(0, 0%, 60%, 0.05);
            }
            .stack-info {
              height: fit-content;
              .title {
                display: block;
                height: 42px;
                line-height: 42px;
                margin-bottom: 10px;
                font-size: 30px;
                font-weight: 600;
                color: #000;
              }
              .header-desc {
                height: 32px;
                font-size: 16px;
                font-weight: 400;
                color: #333;
                line-height: 32px;
                span {
                  color: #0fc700;
                }
              }
            }
          }
          .el-dialog__headerbtn .el-dialog__close {
            font-size: 34px;
          }
        }
        .el-dialog__body {
          padding-top: 20px;
          height: 430px;
        }
      }
    }
  }
  .el-pagination {
    text-align: center;
    margin-bottom: 22px;
  }
}
</style>
