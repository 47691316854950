<template>
  <div class="page-header">
    <div class="page-header-cell container-w1200">
      <div class="page-header-hd">
        <router-link :to="{ name: 'Home' }">
          <img
            class="page-header-logo"
            src="~@/assets/images/common/aitschool_logo.png"
            alt="aitschool"
          />
        </router-link>
      </div>
      <div class="page-header-bd">
        <router-link class="page-header-link" :to="{ name: 'Home' }"
          >首页</router-link
        >
        <span v-if="false">
          <router-link class="page-header-link" :to="{ name: 'Course' }"
            >课程
          </router-link>
          <i
            :class="[
              'el-icon-search',
              'search-icon',
              { 'router-link-exact-active': isClick }
            ]"
            @click.self="isClick = !isClick"
          ></i>
        </span>
        <span v-if="false">
        <transition name="slide-fade">
          <div class="search-container" v-show="isClick">
            <el-input
              placeholder="请输入内容"
              v-model="searchInfo"
              clearable
              prefix-icon="el-icon-search"
              @keyup.enter.native="searchCourse"
              @clear="resetList"
            >
            </el-input>
          </div>
        </transition>
        </span>
        <router-link class="page-header-link" :to="{ name: 'Camps' }" v-if="false"
          >实战营</router-link
        >
        <router-link class="page-header-link" :to="{ name: 'Exam' }"
          >技能</router-link
        >
      </div>
      <userInfo />
    </div>
  </div>
</template>

<script>
import userInfo from "./BasicLayoutHeaderUserInfo.vue";
export default {
  name: "BasicLayoutHeader",
  components: {
    userInfo
  },
  data() {
    return {
      isClick: false,
      searchInfo: ""
    };
  },
  methods: {
    searchCourse() {
      this.$store.commit("SEARCHINFO", this.searchInfo);
      this.$router.push("/search?series_name=" + this.searchInfo);
    },
    resetList() {
      this.searchInfo = "";
      this.$store.commit("SEARCHINFO", this.searchInfo);
      this.$router.push("/course");
    }
  }
};
</script>

<style lang="less">
.page-header {
  position: relative;
  overflow: hidden;
  background: #fff;
  height: 60px;
  box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);

  .page-header-cell {
    display: flex;
    height: 100%;
  }

  .page-header-logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 110px;
    height: 20px;
  }

  .page-header-hd {
    margin-left: 10px;
  }

  .page-header-bd {
    flex: 1;
    padding-left: 20px;
    line-height: 60px;
    font-size: 16px;
    font-weight: 500;
    color: #999;
    .page-header-link {
      margin: 20px;
      transition: all 0.2s;
      &:hover {
        color: #000;
      }
    }
    .search-icon {
      position: relative;
      right: 15px;
    }
    .slide-fade-enter-active {
      transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-leave-active {
      transition: all 0.3s ease;
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateX(-10px);
      opacity: 0;
    }
    .search-container {
      display: inline-block;
    }
  }

  .el-input__inner {
    border-radius: 0;
  }
}
</style>
