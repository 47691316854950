<template>
  <div class="plan-section">
    <div class="container-w1200">
      <div class="plan-title">成长学习计划</div>
      <div class="plan-list">
        <div class="plan-item plan-card">
          <div class="plan-card-hd">
            <img src="@/assets/images/common/plan/wechat.png" alt="wechat" />
          </div>
          <div class="plan-card-bd">
            <div class="plan-card-title">
              微信小程序工程师学习计划
              <span class="plan-card-label">最热</span>
            </div>
            <div class="plan-card-description">
              微信小程序开发工程师是当前互联网行业里亟需招聘岗位，综合运用微信提供的生态和前端技术，集设计、前端、后端、运维于一身，为用户提供接近原生的交互体验，是一个新兴的“全栈”发展方向。
            </div>
            <div class="plan-card-badge">
              <span class="plan-card-badge_text">限时体验</span>
            </div>
          </div>
          <router-link to="/plan/1" class="plan-card-btn">查看详情</router-link>
        </div>
        <div class="plan-item plan-card">
          <div class="plan-card-hd">
            <img src="@/assets/images/common/plan/web.png" alt="web" />
          </div>
          <div class="plan-card-bd">
            <div class="plan-card-title">
              Web 前端工程师学习计划
            </div>
            <div class="plan-card-description">
              Web前端工程师是目前最火的互联网职业，它能充分理解项目需求和设计需求，并与设计师、后端工程师紧密合作，产出高质量的产品前端层，为用户呈现最好的界面交互体验。
            </div>
          </div>
          <span class="plan-card-btn wait">敬请期待</span>
        </div>
        <div class="plan-item plan-card">
          <div class="plan-card-hd">
            <img src="@/assets/images/common/plan/php.png" alt="php" />
          </div>
          <div class="plan-card-bd">
            <div class="plan-card-title">
              PHP 工程师学习计划
            </div>
            <div class="plan-card-description">
              PHP被称为世界上最好的语言，它是一种通用开源脚本语言。语法吸收了C语言、Java和Perl的特点，入门简单，容易掌握，利于学习，使用广泛。PHP语言可操纵多种主流与非主流的数据库、支持面向对象的编程、支持跨平台的操作。
            </div>
          </div>
          <span class="plan-card-btn wait">敬请期待</span>
        </div>
        <div class="plan-item plan-card">
          <div class="plan-card-hd">
            <img src="@/assets/images/common/plan/python.png" alt="python" />
          </div>
          <div class="plan-card-bd">
            <div class="plan-card-title">
              Python 数据分析工程师学习计划
            </div>
            <div class="plan-card-description">
              Python 被广泛的应用在
              Web开发、爬虫开发、数据分析、人工智能、科学运算等各个技术领域，他是工程师的最爱的效率工具，因其庞大的类库，让你的项目能够快速的完成试错，随着大数据和AI时代的开启，Python
              成为数据专家和AI工程师必备的技能之一。
            </div>
          </div>
          <span class="plan-card-btn wait">敬请期待</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {}
};
</script>

<style lang="less" scoped>
.plan-section {
  margin-top: 28px;
  padding-top: 80px;
  padding-bottom: 180px;
  position: relative;
  background: url("~@/assets/images/common/radius-bg.89f75b3.png") top/100%
      100px,
    linear-gradient(#f7f7fb, #f7f7fb);
  background-repeat: no-repeat;

  .plan-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 600;
    color: #333;
    padding: 50px 0 0;
    background: radial-gradient(
          circle,
          #48f4cb 0,
          #48f4cb 25px,
          transparent 0,
          transparent 100%
        )
        calc(50% - 60px) 8px,
      radial-gradient(
          circle,
          #fdc82f 0,
          #fdc82f 12px,
          transparent 0,
          transparent 100%
        )
        calc(50% + 68px) 30px;
    background-repeat: no-repeat;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 4px;
      border-radius: 2px;
      background-color: #31285d;
    }
    &:before {
      margin-right: 16px;
    }
    &:after {
      margin-left: 16px;
    }
  }

  .plan-list {
    margin-top: 80px;
  }

  .plan-item {
    margin-top: 32px;

    &:first-child {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -28px;
        right: 125px;
        z-index: 0;
        width: 216px;
        height: 28px;
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMTYiIGhlaWdodD0iMTI1IiB2aWV3Qm94PSIwIDAgMjE2IDEyNSI+CiAgICA8ZyBmaWxsPSIjNkM0OEU5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9Ii4xMTkiPgogICAgICAgIDxwYXRoIGQ9Ik01Mi4zOCAwTDAgMTI1aDM0LjYxOEw4NyAwek0xMTYuMzgxIDBMNjQgMTI1aDM0LjYxOUwxNTEgMHpNMTgxLjM4MSAwTDEyOSAxMjVoMzQuNjE5TDIxNiAweiIvPgogICAgPC9nPgo8L3N2Zz4K")
          no-repeat;
      }
    }
  }

  .plan-card {
    display: flex;
    position: relative;
    height: 240px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 2px 81px 0 rgba(56, 35, 219, 0.09);
    .plan-card-hd {
      width: 400px;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 16px 0 0 16px;
      }
    }
    .plan-card-bd {
      flex: 1;
      padding: 40px;
    }
    .plan-card-ft {
      width: 200px;
    }
    .plan-card-title {
      font-size: 26px;
      font-weight: 600;
      color: #333;
    }
    .plan-card-label {
      display: inline-block;
      padding: 3px 16px;
      margin-left: 16px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 13px;
      background-color: #f1eff5;
    }
    .plan-card-description {
      margin-top: 20px;
      opacity: 0.5;
      line-height: 28px;
      color: #1b1243;
      text-align: justify;
    }
    .plan-card-badge {
      position: absolute;
      right: -3px;
      top: -3px;
      display: block;
      width: 100px;
      height: 100px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9ImEiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiByeD0iMSIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8bWFzayBpZD0iYiIgZmlsbD0iI2ZmZiI+CiAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHVzZSBmaWxsPSIjRDhEOEQ4IiBmaWxsLW9wYWNpdHk9IjAiIHhsaW5rOmhyZWY9IiNhIi8+CiAgICAgICAgPHBhdGggZmlsbD0iI0IzMkE3OSIgZD0iTTk3IDkyaDN2OGgtM3pNMCAwaDN2M0gweiIgbWFzaz0idXJsKCNiKSIvPgogICAgICAgIDxnIGZpbGw9IiNGRjY2OTQiIG1hc2s9InVybCgjYikiPgogICAgICAgICAgICA8cGF0aCBkPSJNMjEuNDU2LTMwbDExMi40MyAxMTIuNDMtMjUuNDU2IDI1LjQ1NkwtNC00LjU0NHoiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=);
      .plan-card-badge_text {
        position: absolute;
        top: 27px;
        left: 31px;
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .plan-card-btn {
      position: absolute;
      right: 40px;
      bottom: 20px;
      border: none;
      padding: 8px 28px;
      border-radius: 24px;
      background: #111;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 24px;
      transform: all 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
      &.wait {
        background-color: #1f0d62 !important;
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
}
</style>
