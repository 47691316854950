import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes.js";
import userService from "./../global/services/user";
import cookies from "js-cookie";
import Store from "@/store/index";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
router.firstInit = false;
router.beforeEach(async (to, from, next) => {
  const TOKEN = cookies.get("web_token");
  if (TOKEN && !router.firstInit) {
    try {
      const userInfo = await userService.getUserInfo();
      Store.commit("USERINFO", userInfo.data);
      router.firstInit = true;
    } catch (e) {
      next();
    }
  }
  next();
});
export default router;
