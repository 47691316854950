import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    searchInfo: ""
  },
  mutations: {
    USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SEARCHINFO(state, searchInfo) {
      state.searchInfo = searchInfo;
    }
  },
  actions: {},
  modules: {}
});
