<template>
  <div class="security-list">
    <div class="security-item">
      <span class="name">手机号</span
      ><span class="state">{{ userInfo.phone }}</span>
      <el-button class="operation" type="text" @click="outerVisible = true"
        >修改手机号</el-button
      >
      <el-dialog
        title="验证身份"
        :visible.sync="outerVisible"
        center
        width="500px"
        @close="resetForm('oldForm', oldForm)"
      >
        <span class="dialog-tip"
          >为了保证你的帐号安全，请验证身份。验证成功后进行下一步操作。</span
        >
        <el-form :model="oldForm" :rules="rules" ref="oldForm">
          <el-form-item prop="phone">
            <el-input
              v-model="oldForm.phone"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item prop="verity">
            <el-input
              v-model="oldForm.verity"
              autocomplete="off"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              type="text"
              v-bind="{ disabled: oldForm.isdisabled }"
              @click="getVerity('oldForm', oldForm)"
              >{{ this.oldForm.verityBtn }}</el-button
            >
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="changePhone">确定</el-button>
        </div>
        <el-dialog
          center
          width="360px"
          title="修改手机号"
          :visible.sync="innerVisible"
          append-to-body
          @close="resetForm('newForm', newForm)"
        >
          <el-form :model="newForm" ref="newForm" :rules="rules">
            <el-form-item prop="phone">
              <el-input
                v-model="newForm.phone"
                placeholder="请输入手机号"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item prop="verity">
              <el-input
                v-model="newForm.verity"
                autocomplete="off"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                type="text"
                @click="getVerity('newForm', newForm)"
                v-bind="{ disabled: newForm.isdisabled }"
                >{{ this.newForm.verityBtn }}</el-button
              >
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitChange">确定</el-button>
          </div>
        </el-dialog>
      </el-dialog>
    </div>
    <div class="security-item">
      <span class="name">微信</span
      ><span class="state" v-if="userInfo.unionid">已绑定</span
      ><span class="state" v-else>未绑定</span
      ><span class="operation" v-if="userInfo.unionid" @click="unbindWechat"
        >解绑</span
      ><span class="operation" v-else @click="bindWechat">绑定</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import userService from "@/global/services/user.js";
import sms from "@/global/services/sms.js";
export default {
  name: "security",
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      oldForm: {
        phone: "",
        verity: "",
        key: "",
        isdisabled: false,
        verityBtn: "获取验证码",
        time: ""
      },
      rules: {
        verity: [
          {
            message: "请输入验证码",
            required: true,
            trigger: "changePhone"
          },
          { trigger: "change" }
        ],
        phone: [
          {
            message: "请输入手机号",
            required: true,
            trigger: ["submitChange", "getVerity"]
          },
          { trigger: "change" }
        ]
      },
      newForm: {
        phone: "",
        verity: "",
        key: "",
        isdisabled: false,
        verityBtn: "获取验证码",
        time: ""
      }
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    this.$nextTick(() => {
      if (this.getQueryVariable("wechat_bind_status") == 1) {
        this.$message.error("微信已绑定其他账号");
      } else if (this.getQueryVariable("wechat_bind_status") == 2) {
        this.$message.success("绑定成功");
      }
      let newQuery = JSON.parse(JSON.stringify(this.$route.query)); // 深拷贝
      if (newQuery.wechat_bind_status) {
        delete newQuery.wechat_bind_status;
        this.$router.replace({ query: newQuery });
      }
    });
  },
  updated() {
    this.oldForm.phone = this.userInfo.phone;
  },
  methods: {
    resetForm(formName, form) {
      this.$refs[formName].resetFields();
      clearInterval(form.time);
      form.verityBtn = "获取验证码";
      form.isdisabled = false;
      form.key = null;
    },
    getVerity(formName, form) {
      formName == "newForm" && form.phone == this.userInfo.phone
        ? this.$message.error("手机号码已存在，无法绑定")
        : this.$refs[formName].validateField(["phone"], valid => {
            if (!valid) {
              sms.smsCode({ phone: form.phone }).then(res => {
                form.key = res.data.key;
                let seconds = 60;
                form.verityBtn = `${seconds} 秒后重新发送`;
                form.isdisabled = true;
                form.time = setInterval(() => {
                  if (seconds !== 1) {
                    seconds -= 1;
                    form.verityBtn = `${seconds} 秒后重新发送`;
                  } else {
                    clearInterval(form.time);
                    form.verityBtn = "重新发送";
                    form.isdisabled = false;
                  }
                }, 1000);
              });
            }
          });
    },
    changePhone() {
      this.$refs.oldForm.validate(valid => {
        if (valid) {
          sms
            .smsRegister({
              key: this.oldForm.key,
              code: this.oldForm.verity,
              phone: this.userInfo.phone
            })
            .then(() => {
              this.innerVisible = true;
              this.outerVisible = false;
            });
        }
      });
    },
    submitChange() {
      sms
        .smsRegister({
          key: this.newForm.key,
          code: this.newForm.verity,
          phone: this.newForm.phone
        })
        .then(() => {
          const userInfo = { ...this.userInfo };
          userInfo.phone = this.newForm.phone;
          this.$store.commit("USERINFO", userInfo);
          this.innerVisible = false;
          this.$message.success("修改成功");
        });
    },
    bindWechat() {
      const oAuthWebUrl = () => {
        const appId = process.env.VUE_APP_WECHAT_WEB_APPID;
        const redirectUrl =
          "https://api.aitschool.com/api/web/oauth/wechat/bind?redirect_uri=" +
          window.location.href;
        const wechatReqUrl = "https://open.weixin.qq.com/connect/qrconnect";
        const webState = "born2code";
        const wechatQuery = `appid=${appId}&redirect_uri=${redirectUrl}&state=${webState}`;
        const wechatParams =
          "response_type=code&scope=snsapi_login#wechat_redirect";
        return `${wechatReqUrl}?${wechatQuery}&${wechatParams}`;
      };
      window.location.href = oAuthWebUrl();
    },
    unbindWechat() {
      userService.getUnbindWechat().then(res => {
        const userInfo = { ...this.userInfo };
        userInfo.unionid = null;
        this.$store.commit("USERINFO", userInfo);
        this.$message.success(res.msg);
      });
    },
    getQueryVariable: variable => {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    }
  }
};
</script>

<style lang="less" scoped>
.card-box-content {
  padding-top: 20px;
}
.security-list {
  padding: 30px 45px;
  .security-item {
    display: flex;
    align-items: center;
    height: 80px;
    color: 333;
    font-size: 14px;
    .name {
      margin-right: 40px;
      min-width: 42px;
    }
    .state {
      width: 282px;
      color: #666;
    }
    .operation {
      color: #0fc700;
      cursor: pointer;
    }
  }
}
/deep/.el-dialog {
  .el-dialog__header {
    padding: 20px;
    font-size: 26px;
    font-weight: 600;
    color: #333;
    line-height: 37px;
  }
  .el-dialog__body {
    padding: 0 80px !important;
    .dialog-tip {
      display: block;
      text-align: center;
      color: #999;
      margin-bottom: 20px;
    }
    .el-form-item__content {
      width: 100%;
      position: relative;
      font-size: 14px;
      .el-input__inner {
        border-radius: 0;
      }
      .el-button {
        display: inline-block;
        height: 100%;
        padding: 12px 20px;
        position: absolute;
        right: 0;
        outline: none;
        border: none;
        font-size: 12px;
        color: #999;
        background-color: transparent;
      }
    }
  }

  .el-button--primary {
    background-color: #333;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 120px;
    height: 40px;
    border-radius: 0;
    border: none;
    outline: none;
  }
}
</style>
