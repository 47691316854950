<template>
  <div class="courseitem-page font-regular">
    <div class="container-w1240">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/course' }">课程</el-breadcrumb-item>
        <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="course-container">
        <div class="course-left">
          <div class="course-video-session">
            <video
              :src="videoSrc"
              width="100%"
              height="100%"
              ref="videoPlayer"
              autoplay
              controls
            >
              <source :src="videoSrc" type="video/mp4" />
              <source :src="videoSrc" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="course-title-session">
            <h3 class="couse-title">{{ name }}</h3>
            <div class="couse-intro">
              <i class="career-icon"></i> {{ course.core_content }}
            </div>
          </div>
        </div>
        <div class="course-right">
          <div class="course-right-level course-level course-level2"></div>
          <h3 class="course-right-name">{{ name }}</h3>
          <div class="course-right-time">
            <span class="course-time-duration">
              <i class="time-icon"></i
              >{{ (course.total_duration / 60).toFixed(2) }}小时
            </span>
            <span class="separate">...</span>
            <span class="course-time-count">{{ time_count }}课时</span>
            <span class="separate"></span>
          </div>
          <div class="course-play-session" @click="JumpLearn()">
            <i class="to-study-icon"></i>
            开始学习
          </div>
          <div class="course-tabs">
            <div class="course-tabs-header">
              <i class="el-icon-arrow-left" @click="swiperCoursePrev()"></i>
              <div
                class="course-tabs-header-list"
                id="course-tabs-header-list"
                ref="courseTab"
              >
                <span
                  v-for="(item, index) in course.courses"
                  :key="item.id"
                  :class="[
                    'course-tab-list',
                    { 'current-tab': activeTabId == item.id ? true : false }
                  ]"
                  @click="changeCourse(item.id)"
                >
                  第{{ index + 1 }}章
                </span>
              </div>
              <i class="el-icon-arrow-right" @click="swiperCourseNext()"></i>
            </div>
            <div
              class="course-tab-content"
              @scroll="getscroll"
              ref="courseList"
            >
              <div
                class="course-tabs-item"
                v-for="(item, index) in course.courses"
                :key="item.id"
                :id="item.id"
                :prop="item"
              >
                <span class="course-tabs-item-header">
                  第{{ index + 1 }}章 {{ item.name }}
                </span>
                <span
                  class="course-tabs-item-session"
                  v-for="(lesson, index) in item.lessons"
                  :key="lesson.id"
                >
                  <a
                    href="javascript:;"
                    class="course-tabs-item-link"
                    @click="playLesson(lesson, item)"
                  >
                    <span class="course-tabs-item-index">{{ index + 1 }}</span>
                    <span class="course-tabs-item-name">{{ lesson.name }}</span>
                    <span class="course-tabs-item-duration">
                      {{ lesson.duration }}分钟
                    </span>
                    <span
                      href="javascript:;"
                      class="course-catelog-btn green position-abs"
                      >开始学习</span
                    >
                  </a>
                </span>
              </div>
            </div>
            <div class="bottom-mask"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cookies from "js-cookie";
import course from "../../../global/services/course";
export default {
  name: "CourseDetail",
  data() {
    return {
      id: 0,
      name: "",
      course: "",
      time_count: "",
      videoSrc: "",
      activeTabId: 0,
      marginLeft: 0,
      courseTabWidth: 65
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  created() {
    this.id = this.$route.params.id;
    this.getCourseDetail();
  },
  methods: {
    isPermission() {
      const TOKEN = cookies.get("web_token");
      if (TOKEN) {
        let date = new Date();
        let currentDate = date.toLocaleString();
        if (this.userInfo.vip_expired > currentDate) {
          this.$refs.videoPlayer.pause();
          return true;
        } else {
          this.$message.error("请联系管理员获取VIP权限");
          return false;
        }
      } else {
        window.location.href = `https://account${process.env.VUE_APP_DOMAIN}?referer=${window.location.href}`;
      }
    },
    JumpLearn() {
      if (this.isPermission()) {
        window.open(`${process.env.VUE_APP_LEARN}/course/${this.id}`, "_blank");
      }
    },
    getscroll() {
      let filterNav = this.course.courses.filter(courses => {
        let id = courses.id;
        let offsetTop = document.getElementById(id).offsetTop - 55;
        let scrollTop = this.$refs.courseList.scrollTop;
        return scrollTop >= offsetTop;
      });
      let navActivedId = filterNav.length
        ? filterNav[filterNav.length - 1].id
        : "";
      if (navActivedId > this.activeTabId) {
        this.activeTabId = Number(navActivedId);
        this.swiperCourseNext();
      } else if (navActivedId < this.activeTabId) {
        this.activeTabId = Number(navActivedId);
        this.swiperCoursePrev();
      }
    },
    getCourseDetail() {
      course.getCourseDetail(this.id).then(res => {
        this.name = res.data.name;
        this.course = res.data;
        this.activeTabId = res.data.courses[0].id;
        this.activeLessonId = res.data.courses[0].lessons[0].id;
        this.videoSrc = res.data.courses[0].lessons[0].url;
        let count = 0;
        res.data.courses.map(data => {
          return (count += data.lessons.length);
        });
        this.time_count = count;
      });
    },
    changeCourse(id) {
      this.activeTabId = id;
      let offsetTop = document.getElementById(id).offsetTop;
      this.$refs.courseList.scrollTop = offsetTop - 55; //55为导航栏高度加course-tab-content的padding
    },
    playLesson(lesson, item) {
      if (this.isPermission()) {
        window.open(
          `${process.env.VUE_APP_LEARN}/course/${this.id}/${item.id}/${lesson.id}`,
          "_blank"
        );
      }
    },
    swiperCoursePrev() {
      let courseNum = this.$refs.courseTab.children.length;
      if (courseNum > 4 && this.courseTabWidth >= 0) {
        this.marginLeft -= this.courseTabWidth;
      } else {
        this.marginLeft = 0;
      }
      this.$refs.courseTab.setAttribute(
        "style",
        `margin-left:-${this.marginLeft}px`
      );
    },
    swiperCourseNext() {
      let courseNum = this.$refs.courseTab.children.length;
      let MaxLeft = (courseNum - 4) * this.courseTabWidth;
      if (courseNum > 4 && this.marginLeft < MaxLeft) {
        this.marginLeft += this.courseTabWidth;
      } else {
        this.marginLeft = MaxLeft;
      }
      this.$refs.courseTab.setAttribute(
        "style",
        `margin-left:-${this.marginLeft}px`
      );
    }
  }
};
</script>

<style lang="less">
@green: #0fc700;
.font-regular {
  font-weight: 400;
}
.el-breadcrumb {
  margin: 18px 0 14px;
  .el-breadcrumb__inner {
    &,
    &.is-link {
      font-weight: 400;
      color: #666 !important;
    }
  }
}
.container-w1240 {
  width: 1240px;
  margin: 0 auto;
}
.course-container {
  display: flex;
  margin-bottom: 40px;
  background-color: #fff;
  height: 630px;
  font-size: 0;
  .course-left {
    width: 925px;
    height: 100%;
    .course-video-session {
      width: 100%;
      height: 520px;
      cursor: pointer;
      video {
        border-right: 1px solid #f5f5f5;
        border-bottom: 1px solid #f5f5f5;
        &:focus {
          outline: none;
        }
      }
    }
    .course-title-session {
      padding: 28px 0 28px 30px;
      .couse-title {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 28px;
        color: #333;
      }
      .couse-intro {
        color: #999;
        font-size: 16px;
        white-space: nowrap;
        line-height: 16px;
        height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        .career-icon {
          vertical-align: top;
        }
      }
    }
  }
  .course-right {
    width: 315px;
    flex: none;
    font-size: 13.5px;
    padding: 30px 20px 0;
    color: #333;
    .course-right-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      line-height: 28px;
      height: 56px;
      padding-right: 55px;
    }
    .course-right-time {
      margin-top: 5px;
      height: 20px;
      font-size: 14px;
      color: #999;
    }
    .course-play-session {
      margin-top: 20px;
      width: 100%;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      text-align: center;
      background: #333;
      color: #fff;
      cursor: pointer;
    }
    .course-tabs {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .course-tabs-header {
        position: relative;
        text-align: center;
        white-space: nowrap;
        word-break: keep-all;
        overflow: hidden;
        width: 275px;
        .course-tabs-header-list {
          position: relative;
          display: flex;
          padding-left: 5px;
          height: 33px;
          line-height: 20px;
          .course-tab-list {
            width: 41px;
            flex: none;
            display: block;
            margin: 0 12px;
            cursor: pointer;
            transition: all 0.3s ease;
            &.current-tab {
              font-size: 16px;
              font-weight: 600;
              border-bottom: 3px solid @green;
              color: @green;
              z-index: 1;
            }
          }
          &::after {
            display: block;
            content: "";
            position: absolute;
            left: -20px;
            bottom: 1px;
            width: calc(100% + 40px);
            height: 1px;
            background: #eaeaea;
          }
        }
        .el-icon-arrow-left,
        .el-icon-arrow-right {
          position: absolute;
          background: #fff;
          top: 0;
          line-height: 20px;
          cursor: pointer;
          z-index: 2;
        }
        .el-icon-arrow-left {
          left: 0;
        }
        .el-icon-arrow-right {
          right: 0;
        }
      }
      .course-tab-content {
        margin: 0 -22px 0 -20px;
        height: 394px;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        padding: 10px 0 30px;
        .course-tabs-item {
          margin-top: 12px;
          .course-tabs-item-header {
            padding: 0 15px 0 20px;
            display: block;
            font-weight: 600;
            margin: 0 0 10px;
          }
          .course-tabs-item-link {
            padding: 0 15px 0 20px;
            display: flex;
            align-items: center;
            height: 35px;
            line-height: 35px;
            &:hover {
              background: #eaeaea;
              color: @green;
              font-weight: 500;
              .course-tabs-item-index {
                border: 1px solid @green;
              }
              .course-tabs-item-duration {
                display: none;
              }
              .course-catelog-btn {
                display: inline-block;
                flex: 1;
                text-align: center;
              }
            }
            .course-tabs-item-index {
              display: inline-block;
              vertical-align: text-bottom;
              width: 18px;
              height: 18px;
              line-height: 16px;
              text-align: center;
              border: 1px solid #d8d8d8;
              border-radius: 50%;
            }
            .course-tabs-item-name {
              display: inline-block;
              width: 170px;
              margin: 0 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: keep-all;
            }
            .course-tabs-item-duration {
              flex: 1;
              text-align: right;
              color: #999;
            }
            .course-catelog-btn {
              display: none;
            }
          }
        }
      }
      .bottom-mask {
        position: absolute;
        bottom: 0;
        left: -20px;
        width: calc(100% + 40px);
        padding-top: 40px;
        background: linear-gradient(-180deg, hsla(0, 0%, 100%, 0), #fff 75%);
      }
    }
  }
}

.separate {
  display: inline-block;
  position: relative;
  transform: rotate(-90deg) translateY(-25%);
  color: #999;
}
.font-semibold {
  font-weight: 600;
}
</style>
