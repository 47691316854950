import request from "../request/axios";
import API from "../request/api";

const evaluation = {
  getMyExamList: ({ page_size, page }) => {
    return request.get(API.exam, { page_size, page });
  },
  getEvaluationList: ({ page_size, page }) => {
    return request.get(API.evaluationList, { page_size, page });
  },
  postPaper: ({ stack_id }) => {
    return request.post(API.exam, { stack_id });
  }
};
export default evaluation;
