import request from "../request/axios";
import API from "../request/api";
const course = {
  getCourseList: currentPage => {
    return request.get(API.courseList + currentPage);
  },
  getCourseSearch: searchInfo => {
    return request.get(API.courseSearch + searchInfo);
  },
  getCourseDetail: courseId => {
    return request.get(API.courseDetail + courseId);
  },
  getCourseRecord: (page_size, page) => {
    return request.get(API.courseRecord, { page_size, page });
  }
};
export default course;
