var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-page"},[_c('div',{staticClass:"course-mainer container-w1200"},[_c('div',{staticClass:"course-list"},_vm._l((_vm.lists),function(item){return _c('router-link',{key:item.id,staticClass:"course-item",attrs:{"to":{ path: ("/course/" + (item.id)) }}},[_c('div',{staticClass:"course-card"},[_c('div',{staticClass:"course-card-banner"},[_c('div',{staticClass:"cover-img",style:({
                width: '285px',
                height: '170px',
                background: 'url(' + item.cover_url + ') no-repeat center',
                backgroundSize: 'cover'
              })}),_c('span',{class:[
                'course-card-tag',
                'course-card-level',
                _vm.card_level(item.difficulty_level)
              ]}),_c('span',{staticClass:"course-card-category"})]),_c('div',{staticClass:"course-card-content"},[_c('span',{staticClass:"course-card-title"},[_vm._v(_vm._s(item.name))]),_c('h4',{staticClass:"course-card-describe"},[_vm._v(_vm._s(item.introduction))]),_c('div',{staticClass:"course-card-info"},[_c('i',{staticClass:"time-icon-gray"}),_c('span',{staticClass:"course-card-info-time"},[_vm._v(_vm._s((item.total_duration / 60).toFixed(2))+"小时")]),_c('span',{staticClass:"separate"},[_vm._v("...")]),_c('span',{staticClass:"course-card-info-count"},[_vm._v(_vm._s(item.lessons_count)+"课时")]),_c('span',{staticClass:"separate"},[_vm._v("...")]),_c('span',{staticClass:"course-card-info-study"},[_vm._v(_vm._s(item.courses_count)+"人已学习")])]),_c('div',{staticClass:"course-card-price font-semibold"},[_c('div',{staticClass:"course-card-price-content"},[_vm._v("免费")]),_c('div',{staticClass:"course-card-study-btn"},[_c('button',{staticClass:"theme-dark-mini-btn",attrs:{"size":"mini"}},[_vm._v(" 立即学习"),_c('i',{staticClass:"link-white-arrow"})])])])])])])}),1),_c('div',{staticClass:"pagination-container"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.total.per_page,"total":_vm.total.total,"current-page":_vm.currentPage},on:{"current-change":_vm.changePage}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }