const API_PREFIX = "https://api" + process.env.VUE_APP_DOMAIN;
export default {
  smsCode: `${API_PREFIX}/api/web/sms/code`,
  smsRegister: `${API_PREFIX}/api/web/sms/register`,
  userInfo: `${API_PREFIX}/api/web/users/user-info`,
  qiniuToken: `${API_PREFIX}/api/file/qiniu-token`,
  qiniuStore: `${API_PREFIX}/api/file/qiniu-store`,
  district: `${API_PREFIX}/api/districts/all`,
  bindPhone: `${API_PREFIX}/api/web/sms/bind`,
  unbindWechat: `${API_PREFIX}/api/web/oauth/wechat/unbind`,
  courseList: `${API_PREFIX}/api/web/course/series?page=`,
  courseSearch: `${API_PREFIX}/api/web/course/series?series_name=`,
  courseDetail: `${API_PREFIX}/api/web/course/series/`,
  courseRecord: `${API_PREFIX}/api/web/course/series/my`,
  campsList: `${API_PREFIX}/api/web/camps`,
  evaluationList: `${API_PREFIX}/api/web/question-stack`,
  exam: `${API_PREFIX}/api/web/question/exam`
};
