<template>
  <div class="page--footer">
    <div class="page--footer__container">
      Copyright © 2020 ❤️ 扎克斯体验技术部出品
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备18089899号 </a>
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011802000279"
        target="_blank"
      >
        <img
          style="width: 16px;height: 16px;vertical-align: middle;margin-left: 4px;"
          class="beian-img"
          src="http://www.beian.gov.cn/img/ghs.png"
        />
      </a>
      粤公网安备 44011802000279号
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicLayoutFooter",
  components: {}
};
</script>

<style lang="less">
.page--footer {
  color: #8e8e8e;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  background-color: #252222;
}
</style>
