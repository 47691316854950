import request from "@/global/request/axios.js";
import API from "@/global/request/api.js";

const userService = {
  getUserInfo: () => {
    return request.get(API.userInfo);
  },
  putUserInfo: ({
    nickname,
    avatar_url,
    gender,
    birthday,
    introduction,
    province,
    city,
    district
  }) => {
    return request.put(API.userInfo, {
      nickname,
      avatar_url,
      gender,
      birthday,
      introduction,
      province,
      city,
      district
    });
  },
  getDistrict: () => {
    return request.get(API.district);
  },
  getUnbindWechat: () => {
    return request.get(API.unbindWechat);
  }
};

export default userService;
