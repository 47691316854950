<template>
  <div v-loading="loading">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="userForm"
      label-width="100px"
    >
      <el-form-item label="头像" prop="avatar">
        <Upload :imagePath.sync="ruleForm.avatar_url" />
      </el-form-item>
      <el-form-item label="昵称" prop="ruleForm">
        <el-input v-model="ruleForm.nickname" :rule="rules.nickname"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="ruleForm.gender" placeholder="请选择性别">
          <el-option label="保密" :value="0" />
          <el-option label="男" :value="1" />
          <el-option label="女" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker
          v-model="ruleForm.birthday"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-M-d"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="现居住地" prop="area">
        <el-cascader v-model="areaValue" :options="area"></el-cascader>
      </el-form-item>
      <el-form-item label="个人简介" prop="cascade">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="一句话啊介绍一下自己吧，让别人更了解你"
          maxlength="100"
          v-model="ruleForm.introduction"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Upload from "@/components/UploadImageAvatar";
import User from "@/global/services/user.js";
export default {
  name: "my",
  components: { Upload },
  data() {
    return {
      loading: true,
      area: [],
      areaValue: [],
      ruleForm: {
        gender: "",
        nickname: "",
        birthday: "",
        introduction: ""
      },
      rules: {
        nickname: [
          {
            validator: this.checkName,
            required: true,
            trigger: ["submitForm"]
          },
          {
            trigger: ["change"]
          }
        ]
      }
    };
  },
  created() {
    User.getDistrict()
      .then(res => {
        this.ruleForm = { ...this.userInfo };
        this.area = res.data;
        this.areaValue = [
          this.userInfo.province,
          this.userInfo.city,
          this.userInfo.district
        ];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    checkName(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入昵称"));
      } else if (!value.match(/^([a-zA-Z0-9_-\u4e00-\u9fa5]{4,16})$/)) {
        return callback(
          new Error("支持中文、英文、数字、'-'、'_'的组合,4-16个字符")
        );
      } else {
        return callback();
      }
    },
    submitForm() {
      const userInfo = { ...this.ruleForm };
      userInfo.province = this.areaValue[0];
      userInfo.city = this.areaValue[1];
      userInfo.district = this.areaValue[2];
      this.$refs.userForm.validate(valid => {
        if (valid) {
          this.loading = true;
          User.putUserInfo(userInfo)
            .then(res => {
              this.$message.success(res.msg);
              this.$store.commit("USERINFO", userInfo);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.card-box-content {
  padding: 45px 30px 0;
  .el-form {
    width: 430px;
    .el-form-item {
      margin-bottom: 40px;
      .el-form-item__label {
        padding-right: 30px;
      }
      .el-input__inner {
        width: 340px;
        border-radius: 0;
      }
      .el-date-editor {
        width: 340px;
      }
      .el-textarea__inner {
        min-height: 33px;
        border-radius: 0;
        width: 340px;
      }
    }
    .el-button {
      width: 120px;
      height: 32px;
      line-height: 32px;
      padding: 0;
      background-color: #333;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      border: none;
      outline: none;
      border-radius: 0;
    }
  }
}
</style>
