<template>
  <div class="current-page">
    <div class="page-mainer container-1200">
      <div class="page-mainer-sider">
        <div class="userInfo-section">
          <el-avatar
            :src="userInfo.avatar_url || imageUrl"
            fit="cover"
            class="userInfo-avatar"
          ></el-avatar>
          <div class="user-content">
            <div class="userInfo-content-name font-semibold">
              {{ userInfo.nickname || userInfo.phone }}
            </div>
            <i class="userInfo-content-vip"></i>
          </div>
        </div>
        <el-menu router default-active="my" class="el-menu-vertical-demo">
          <el-menu-item index="my">
            <i class="el-icon-menu"></i>
            <span slot="title">个人资料</span>
          </el-menu-item>
          <el-menu-item index="currency">
            <i class="el-icon-menu"></i>
            <span slot="title">我的极客币</span>
          </el-menu-item>
          <el-menu-item index="vip">
            <i class="el-icon-document"></i>
            <span slot="title">我的VIP</span>
          </el-menu-item>
          <el-menu-item index="order">
            <i class="el-icon-setting"></i>
            <span slot="title">课程订单</span>
          </el-menu-item>
          <el-menu-item index="security">
            <i class="el-icon-setting"></i>
            <span slot="title">账号安全</span>
          </el-menu-item>
          <el-menu-item index="team">
            <i class="el-icon-setting"></i>
            <span slot="title">我的团队</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="page-mainer-content">
        <div class="card-box">
          <div class="card-box-title font-semibold">
            {{ this.$route.meta.name }}
          </div>
          <router-view class="card-box-content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Setting",
  watch: {
    $router: ""
  },
  data() {
    return {
      isVIP: true,
      imageUrl:
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
    };
  },
  computed: {
    ...mapState(["userInfo"])
  }
};
</script>
<style lang="less" scoped>
.font-semibold {
  font-weight: 600;
}
.current-page {
  flex: auto;
  padding: 20px 0;
  background-color: #f5f5f5;
  .container-1200 {
    width: 1200px;
    margin: 0 auto;
  }
  .page-mainer {
    display: flex;
    height: 100%;
    .page-mainer-sider {
      flex: none;
      margin-right: 20px;
      background: #fff;
      .userInfo-section {
        display: flex;
        align-items: center;
        width: 295px;
        padding: 26px 16px 0 0;
        background-color: #fff;
        .userInfo-avatar {
          display: block;
          width: 60px;
          height: 60px;
          margin-left: 36px;
          margin-right: 15px;
          line-height: 40px;
          text-align: center;
          background-color: #ccc;
          border-radius: 50%;
          outline: none;
        }
        .userInfo-content {
          .userInfo-content-name {
            height: 25px;
            font-size: 18px;
            color: #333;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          /deep/.userInfo-content-vip {
            display: inline-block;
            width: 34px;
            height: 16px;
            background: url("https://www.jiker.com/_nuxt/img/29007b6.svg")
              no-repeat 0 0;
          }
        }
      }
      .el-menu {
        padding-top: 30px;
      }
    }
    .page-mainer-content {
      flex: 1 1;
      .card-box {
        background-color: #fff;
        min-height: 436px;
        padding: 30px 30px 60px 50px;
        .card-box-title {
          height: 33px;
          margin-bottom: 30px;
          font-size: 24px;
          color: #333;
          line-height: 33px;
        }
        .font-semibold {
          font-weight: 600;
        }
        .card-box-content {
          border-top: 1px solid #d8d8d8;
        }
      }
    }
  }
}
</style>
