import request from "../request/axios";
import API from "../request/api";
const camps = {
  getCamps: () => {
    return request.get(API.campsList);
  },
  getCampsList: listId => {
    return request.get(API.campsList + "/" + listId);
  },
  getCampsItem: ({ listId, itemId }) => {
    return request.get(API.campsList + `/${listId}/pages/${itemId}`);
  }
};
export default camps;
