<template>
  <div class="camp-page">
    <section class="banner-section">
      <span class="section-title font-semibold">极客实战营</span>
      <span class="section-desc font-semibold">真实项目驱动+基于业务场景</span>
      <span class="section-desc font-semibold">
        和<span class="highlight">500万用户一起</span
        >，聚焦单点，让开发技能迅速进阶
      </span>
    </section>
    <section class="hot-section">
      <div class="section-title-container">
        <span class="section-title">热门实战营</span
        ><span class="section-subtitle">
          不只是授课，项目驱动、刻意训练，为学习结果负责</span
        >
      </div>
      <div class="plus-list">
        <router-link
          class="plus-item"
          :to="{ name: 'CampsDetail', params: { id: item.id } }"
          v-for="item in campsList"
          :key="item.id"
        >
          <div class="card-plus-top">
            <span class="card-plus-tag"></span>
            <span class="card-plus-title">{{ item.name }}</span>
          </div>
          <div class="card-plus-bottom">
            <div class="card-plus-time">
              <span class="card-plus-time-item"
                ><i class="time-icon"></i>{{ item.duration }}天</span
              >
              <span class="separate">…</span>
              <span class="card-plus-time-item"
                >难度：{{ item.stage_label }}</span
              >
            </div>
            <div class="card-plus-desc">{{ item.name }}</div>
            <div class="card-plus-user">
              <i class="career-icon"></i>
              {{ item.type_label }}
            </div>
          </div></router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import camps from "../../global/services/camps";
export default {
  name: "Camps",
  data() {
    return {
      campsList: ""
    };
  },
  created() {
    this.getCamp();
  },
  methods: {
    getCamp() {
      camps.getCamps().then(res => {
        this.campsList = res.data;
      });
    }
  }
};
</script>

<style lang="less">
@green: #0fc700;
.camp-page {
  .banner-section {
    width: 100%;
    height: 560px;
    padding: 115px calc(50% - 620px) 203px;
    background: url("../../assets/images/common/banner/banner-combat.jpg")
      no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    span[class*="section"] {
      display: block;
      padding-left: 169px;
    }
    .section-desc {
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 4px;
    }
    .section-title {
      font-size: 64px;
      margin-bottom: 8px;
      line-height: 90px;
    }
    .highlight {
      color: @green;
    }
  }
  .hot-section {
    width: 100%;
    min-width: 1240px;
    padding: 40px calc(50% - 620px);
    font-size: 0;
    .section-title-container {
      padding-left: 10px;
      margin-bottom: 18px;
      height: 37px;
      span[class*="section"] {
        vertical-align: baseline;
      }
      .section-title {
        margin-right: 10px;
        font-size: 26px;
        font-weight: 700;
        color: #333;
      }
      .section-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #666;
      }
    }
    .plus-list {
      display: flex;
      flex-wrap: wrap;
      .plus-item {
        display: inline-block;
        width: 290px;
        border-radius: 2px;
        background: #fff;
        text-align: left;
        transition: all 0.2s ease;
        margin: 0 10px 23px;
        font-size: 14px;
        &:first-child {
          width: 600px;
        }
        .card-plus-top {
          width: 100%;
          display: inline-block;
          vertical-align: baseline;
          background: #232323;
          padding: 20px 25px;
          height: 170px;
          .card-plus-title {
            display: table-cell;
            vertical-align: bottom;
            height: 76px;
            color: #efefeff0;
            width: 100%;
            font-size: 24px;
            line-height: 38px;
            font-weight: 800;
            word-spacing: 0.3em;
          }
        }
        .card-plus-bottom {
          color: #333;
          padding: 17px 25px 30px;
          .card-plus-time {
            .card-plus-time-item {
              height: 20px;
              line-height: 20px;
            }
          }
          .card-plus-desc {
            margin: 13px 0 18px;
          }
        }
      }
    }
  }
}
</style>
