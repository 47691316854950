<template>
  <div>
    <div class="course-list">
      <div
        class="course-card"
        v-for="item in lists"
        :key="item.id"
        @click="startLearn(item)"
      >
        <div class="course-card-banner">
          <div
            class="cover-img"
            :style="{
              width: '285px',
              height: '170px',
              background: 'url(' + item.cover_url + ') no-repeat center',
              backgroundSize: 'cover'
            }"
          ></div>
          <span
            :class="[
              'course-card-tag',
              'course-card-level',
              card_level(item.difficulty_level)
            ]"
          ></span>
          <span class="course-card-category"></span>
        </div>
        <div class="course-card-content">
          <span class="course-card-title">{{ item.name }}</span>
          <h4 class="course-card-describe">{{ item.introduction }}</h4>
          <div class="course-card-info">
            <i class="time-icon-gray"></i>
            <span class="course-card-info-time"
              >{{ (item.total_duration / 60).toFixed(2) }}小时</span
            >
            <span class="separate">...</span>
            <span class="course-card-info-count"
              >{{ item.lessons_count }}课时</span
            >
            <span class="separate">...</span>
            <span class="course-card-info-study"
              >{{ item.courses_count }}人已学习</span
            >
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="page_size"
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import course from "@/global/services/course.js";
import cookies from "js-cookie";
export default {
  data() {
    return {
      lists: null,
      pagination: null,
      total: 0,
      page_size: 9,
      courseId: "",
      currentPage: 1
    };
  },
  created() {
    this.getCourse();
    this.search = this.searchInfo;
  },
  methods: {
    card_level(level) {
      return `course-card-level${level}`;
    },
    getCourse() {
      let page = this.currentPage;
      course.getCourseRecord(this.page_size, page).then(res => {
        let lists = res.data.data.list;
        this.lists = lists.reverse();
        this.pagination = res.data.data.pagination;
        this.total = res.data.data.pagination.total;
      });
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getCourse();
    },
    startLearn(item) {
      let seriesId = item.series_id;
      let courseId = item.last_play_course_id;
      let lessonId = item.last_play_lesson_id;
      const TOKEN = cookies.get("web_token");
      if (TOKEN) {
        window.open(
          `${process.env.VUE_APP_LEARN}/course/${seriesId}/${courseId}/${lessonId}`,
          "_blank"
        );
      } else {
        window.location.href = `https://account${process.env.VUE_APP_DOMAIN}?referer=${window.location.href}`;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.course-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  .course-card {
    position: relative;
    display: inline-block;
    &:hover {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      .course-card-price-content {
        opacity: 0;
      }
      .course-card-study-btn {
        opacity: 1 !important;
      }
    }
    &:nth-child(3n + 2) {
      margin: 0 15px;
    }
    &:nth-child(n + 4) {
      margin-top: 20px;
    }
    width: 285px;
    cursor: pointer;
    background-color: #fff;
    .course-card-banner {
      width: 100%;
      height: 170px;
    }
    .course-card-tag {
      position: absolute;
      top: 0;
      text-align: center;
      font-size: 14px;
      line-height: 26px;
      border-radius: 0 0 11px;
    }
    .course-card-level2 {
      background: url("../../../assets/images/icon/course_level/2.svg")
        no-repeat;
    }
    .course-card-level3 {
      background: url("../../../assets/images/icon/course_level/3.svg")
        no-repeat;
    }
    .course-card-level1 {
      background: url("../../../assets/images/icon/course_level/1.svg")
        no-repeat;
    }
    .course-card-level {
      right: 10px;
      width: 45px;
      height: 28px;
    }
    .course-card-content {
      padding: 15px 20px 20px 25px;
      .course-card-title {
        display: -webkit-box;
        height: 56px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 0 7px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
      }
      .course-card-describe {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #666;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
        font-weight: 400;
      }
      .course-card-info {
        color: #999;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .separate {
          position: relative;
          transform: rotate(-90deg) translateY(-25%);
        }
      }
      .course-card-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        margin-top: 12px;
        color: #ff4000;
        .course-card-price-content {
          font-size: 16px;
        }
        .course-card-study-btn {
          opacity: 0;
          transition: all 0.8s;
          .theme-dark-mini-btn {
            color: #fff;
            background-color: #333;
            border: none;
            border-radius: 0;
            width: 105px;
            padding: 0 16px;
            font-size: 12px;
            height: 32px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.el-pagination {
  text-align: center;
}
</style>
