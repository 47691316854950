<template>
  <div>
    <div class="card-box">
      <div class="card-box-title font-semibold">
        {{ this.$route.meta.name }}
      </div>
    </div>
    <div class="card-list">
      <span class="card-item" v-for="item in evaluationList" :key="item.id">
        <span class="img-container"><img :src="item.stack.image_url"/></span>
        <span class="stack">
          <span class="stack-name">{{ item.stack.name }}</span>
        </span>
        <span
          class="show-result"
          @click="showResult(item)"
          v-if="isFinished(item)"
          v-show="item.correct_count !== 0 || item.error_count !== 0"
          ><span>{{ rightRate(item) }}</span
          ><span>查看结果</span>
        </span>
        <span
          class="show-result"
          @click="handleOpen(item)"
          v-else
          v-show="item.correct_count !== 0 || item.error_count !== 0"
          ><span>{{ `${item.correct_count}  | ${item.question_count}` }}</span
          ><span>继续答题</span>
        </span>
        <el-button
          type="text"
          v-show="item.correct_count == 0 && item.error_count == 0"
          @click="handleOpen(item)"
          >继续答题 >
        </el-button>
        <Dialog
          :visible="dialogVisible"
          @dialogClose="Close_dialog"
          :item="current_item"
        ></Dialog>
      </span>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="pagination.total"
      :page-size="page_size"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import exam from "@/global/services/evaluation.js";
import Dialog from "../../Exam/widgets/Dialog";
export default {
  components: { Dialog },
  data() {
    return {
      evaluationList: "",
      page_size: 18,
      current_page: 1,
      pagination: "",
      current_item: {},
      dialogVisible: false
    };
  },
  created() {
    this.getPaperList();
  },
  computed: {
    rightRate() {
      return function(item) {
        return (
          ((item.correct_count / item.question_count) * 100).toFixed(2) + "%"
        );
      };
    },
    isFinished() {
      return function(item) {
        return Number(item.correct_count) + Number(item.error_count) == 20;
      };
    }
  },
  methods: {
    getPaperList() {
      let page = this.current_page;
      exam
        .getMyExamList({ page_size: this.page_size, page: page })
        .then(res => {
          this.evaluationList = res.data.data.list;
          this.pagination = res.data.data.pagination;
        });
    },
    handleOpen(item) {
      this.dialogVisible = true;
      this.current_item = item.stack;
    },
    Close_dialog() {
      this.dialogVisible = false;
    },
    changePage(current_page) {
      this.current_page = current_page;
      this.getPaperList();
    },
    showResult(item) {
      window.location.href = `${process.env.VUE_APP_LEARN}/result/${item.id}`;
    }
  }
};
</script>

<style lang="less" scoped>
.card-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 22px;
  .card-item {
    display: flex;
    align-items: center;
    width: 285px;
    height: 78px;
    border: 1px solid #ebebeb;
    background: #fff;
    margin: 0 0 22px;
    padding: 19px 15px;
    &:nth-child(3n + 2) {
      margin: 0 15px 22px;
    }
    &:hover {
      box-shadow: 0 3px 5px #ddd;
    }
    .img-container {
      display: inline-block;
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-right: 5px;
      img {
        max-width: 100%;
      }
    }
    .stack {
      flex: 1;
      .stack-name {
        font-size: 18px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #333;
        line-height: 26px;
      }
    }
    /deep/.el-button {
      cursor: pointer;
      outline: none;
      text-align: center;
      span {
        line-height: 32px;
        position: relative;
        .arrow-right {
          line-height: 16px;
          vertical-align: text-top;
        }
      }
    }
    .show-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1764e8;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    /deep/.el-dialog {
      .el-dialog__header {
        padding: 50px 80px 6px;
        height: fit-content;
        .dialog-header {
          display: flex;
          img {
            width: 88px;
            height: 88px;
            margin-right: 6px;
            border-radius: 50%;
            border: 1px solid hsla(0, 0%, 60%, 0.05);
          }
          .stack-info {
            height: fit-content;
            .title {
              display: block;
              height: 42px;
              line-height: 42px;
              margin-bottom: 10px;
              font-size: 30px;
              font-weight: 600;
              color: #000;
            }
            .header-desc {
              height: 32px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              line-height: 32px;
              span {
                color: #0fc700;
              }
            }
          }
        }
        .el-dialog__headerbtn .el-dialog__close {
          font-size: 34px;
        }
      }
      .el-dialog__body {
        padding-top: 20px;
        height: 430px;
      }
    }
  }
}
.el-pagination {
  text-align: center;
  margin-bottom: 22px;
}
</style>
