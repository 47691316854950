<template>
  <div class="course-page">
    <div class="course-mainer container-w1200">
      <div class="course-list">
        <router-link
          :to="{ path: `/course/${item.id}` }"
          v-for="item in lists"
          :key="item.id"
          class="course-item"
        >
          <div class="course-card">
            <div class="course-card-banner">
              <div
                class="cover-img"
                :style="{
                  width: '285px',
                  height: '170px',
                  background: 'url(' + item.cover_url + ') no-repeat center',
                  backgroundSize: 'cover'
                }"
              ></div>
              <span
                :class="[
                  'course-card-tag',
                  'course-card-level',
                  card_level(item.difficulty_level)
                ]"
              ></span>
              <span class="course-card-category"></span>
            </div>
            <div class="course-card-content">
              <span class="course-card-title">{{ item.name }}</span>
              <h4 class="course-card-describe">{{ item.introduction }}</h4>
              <div class="course-card-info">
                <i class="time-icon-gray"></i>
                <span class="course-card-info-time"
                  >{{ (item.total_duration / 60).toFixed(2) }}小时</span
                >
                <span class="separate">...</span>
                <span class="course-card-info-count"
                  >{{ item.lessons_count }}课时</span
                >
                <span class="separate">...</span>
                <span class="course-card-info-study"
                  >{{ item.courses_count }}人已学习</span
                >
              </div>
              <div class="course-card-price font-semibold">
                <div class="course-card-price-content">免费</div>
                <div class="course-card-study-btn">
                  <button size="mini" class="theme-dark-mini-btn">
                    立即学习<i class="link-white-arrow"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="total.per_page"
          :total="total.total"
          :current-page="currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import course from "../../global/services/course.js";
import { mapState } from "vuex";
export default {
  name: "Course",
  data() {
    return {
      lists: null,
      total: {},
      currentPage: 1,
      search: ""
    };
  },
  watch: {
    $route: function() {
      this.$store.commit("SEARCHINFO", this.getQueryVariable("series_name"));
      this.getCourse();
    }
  },
  created() {
    this.getCourse();
    this.search = this.searchInfo;
  },
  computed: {
    ...mapState(["searchInfo"])
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.getCourse();
    },
    getQueryVariable: variable => {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
    },
    getCourse() {
      if (this.searchInfo) {
        course.getCourseSearch(this.searchInfo).then(res => {
          this.total = res.data.data.pagination;
          this.lists = res.data.data.list;
        });
      } else {
        course.getCourseList(this.currentPage).then(res => {
          this.total = res.data.data.pagination;
          this.lists = res.data.data.list;
        });
      }
    },
    card_level(level) {
      return `course-card-level${level}`;
    }
  }
};
</script>

<style lang="less" scoped>
.course-mainer {
  padding: 20px 0 40px;
  .course-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    .course-item {
      margin: 0 20px 30px 0;
      &:hover {
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        .course-card-price-content {
          opacity: 0;
        }
        .course-card-study-btn {
          opacity: 1 !important;
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      display: inline-block;
      .course-card {
        position: relative;
        display: inline-block;
        width: 285px;
        cursor: pointer;
        background-color: #fff;
        .course-card-banner {
          width: 100%;
          height: 170px;
        }
        .course-card-tag {
          position: absolute;
          top: 0;
          text-align: center;
          font-size: 14px;
          line-height: 26px;
          border-radius: 0 0 11px;
        }
        .course-card-level2 {
          background: url("../../assets/images/icon/course_level/2.svg")
            no-repeat;
        }
        .course-card-level3 {
          background: url("../../assets/images/icon/course_level/3.svg")
            no-repeat;
        }
        .course-card-level1 {
          background: url("../../assets/images/icon/course_level/1.svg")
            no-repeat;
        }
        .course-card-level {
          right: 10px;
          width: 45px;
          height: 28px;
        }
        .course-card-content {
          padding: 15px 20px 20px 25px;
          .course-card-title {
            display: -webkit-box;
            height: 56px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 0 7px;
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
          }
          .course-card-describe {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #666;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
            font-weight: 400;
          }
          .course-card-info {
            color: #999;
            font-size: 13px;
            line-height: 20px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            .separate {
              position: relative;
              transform: rotate(-90deg) translateY(-25%);
            }
          }
          .course-card-price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;
            margin-top: 12px;
            color: #ff4000;
            .course-card-price-content {
              font-size: 16px;
            }
            .course-card-study-btn {
              opacity: 0;
              transition: all 0.8s;
              .theme-dark-mini-btn {
                color: #fff;
                background-color: #333;
                border: none;
                border-radius: 0;
                width: 105px;
                padding: 0 16px;
                font-size: 12px;
                height: 32px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  .pagination-container {
    margin: 2px auto 0;
    text-align: center;
  }
  .font-semibold {
    font-weight: 600;
  }
}
</style>
