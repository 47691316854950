<template>
  <div class="home-page">
    <Banner />
    <Plan />
    <div class="container-w1200">
      <Partner />
    </div>
  </div>
</template>

<script>
import Banner from "./widgets/Banner.vue";
import Plan from "./widgets/Plan.vue";
import Partner from "./widgets/Partner.vue";

export default {
  name: "Home",
  components: {
    Partner,
    Plan,
    Banner
  }
};
</script>

<style type="text/css" lang="less" scoped></style>
