<template>
  <div class="plus-detail-page">
    <section class="banner-section">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/plus' }">实战营</el-breadcrumb-item>
        <el-breadcrumb-item>{{ plusList.name }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="banner-content">
        <div class="banner-card">
          <div class="card-hd">
            <span class="card-open">开营日期&nbsp;待定</span>
            <span class="card-duration font-semibold">
              <i class="time-icon-gray"></i>
              {{ plusList.duration }}天
            </span>
          </div>
          <div class="card-bd">
            <div class="card-title font-semibold">主讲：</div>
            <div class="card-desc">
              <i class="career-icon"></i>{{ plusList.mentor_name }}
            </div>
            <div class="card-title font-semibold">教学目标</div>
            <div class="card-desc">
              <i class="career-icon"></i>{{ plusList.description }}
              <span class="tooltiptext">{{ plusList.description }}</span>
            </div>
          </div>
          <div class="card-ft">
            <a href="javascipt:;" class="card-btn" v-if="userInfo.id"
              >立即报名</a
            >
            <a href="javascipt:;" class="card-btn" v-else>登录</a>
          </div>
        </div>
        <div class="banner-introduction">
          <i class="card-plus-tag"></i>
          <h1 class="banner-title font-semibold">{{ plusList.name }}</h1>
          <div class="banner-desc">
            <span>大神教练带队 </span
            >{{ `/ ${plusList.duration}天 ${itemLength}个项目` }}&nbsp;
          </div>
          <div class="information-container">
            <div class="information-item">
              <div class="information-title font-semibold">
                {{ projectLen }}
              </div>
              <div class="information-desc">实战营项目</div>
            </div>
            <!-- <div class="information-item">
              <div class="information-title font-semibold">{{ 24 }}</div>
              <div class="information-desc">包含任务数</div>
            </div> -->
            <div class="information-item">
              <div class="information-title font-semibold">{{ skillLen }}</div>
              <div class="information-desc">涉及技能点</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="project-section">
      <span class="section-title font-semibold">
        大大转变学习、<br />
        授课和创造的方式，项目实战教学助力进阶
      </span>
      <div class="project-list">
        <div
          class="project-item"
          v-for="(item, index) in plusList.contents"
          :key="item.id"
          @click="JumpProject(item.content_type, item.content_id)"
        >
          <div class="project-item-subtitle">
            {{ item.content_type }}
            <span class="subtitle-span" v-if="index < 9">{{
              `0${index + 1}`
            }}</span>
            <span class="subtitle-span" v-else> {{ index + 1 }}</span>
          </div>
          <div class="project-item-title" v-if="item.content_type == 'page'">
            {{ item.page.name }}
          </div>
          <div class="project-item-title" v-else>{{ item.project.name }}</div>
        </div>
        <el-dialog
          :visible.sync="dialogVisible"
          fullscreen
          custom-class="page-item"
          :before-close="handleClose"
        >
          <span v-html="pagecontent" style="width:100%"></span>
        </el-dialog>
      </div>
    </section>
    <section class="introduction-section">
      <span class="section-title font-semibold"
        >精心打磨课程体系，轻松简单，妙不可言</span
      >
      <div class="introduction-table">
        <div class="nav-list">
          <span
            v-for="(item, index) in projects"
            :key="item.id"
            :class="[
              'nav-item',
              'font-semibold',
              { active: index == activeProjectIndex ? true : false }
            ]"
            @click="changeActiveItem(index)"
          >
            {{ item.project.name }}
          </span>
        </div>
        <div class="table-container">
          <div class="table-title font-semibold">
            {{ activeProject.name }}
          </div>
          <div class="table-item">
            <span class="item-th"> 项目介绍</span>
            <span class="item-td">{{ activeProject.scene }}</span>
          </div>
          <div class="table-item">
            <span class="item-th">教学时长</span>
            <span class="item-td">{{ activeProject.duration }}小时 </span>
          </div>
          <div class="table-item">
            <span class="item-th">技能知识点</span>
            <span class="item-td">{{ skillList }} </span>
          </div>
          <div class="table-item">
            <span class="item-th">能力目标</span>
            <span class="item-td">{{ activeProject.study_aim }} </span>
          </div>
        </div>
      </div>
    </section>
    <section class="service-section">
      <div class="service-list">
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/44a77d0.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">线上学习</div>
          <div class="item-desc">线上系统教学，无约束每天8小时面对面讲授</div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/36d1c60.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">视频回看</div>
          <div class="item-desc">全程录制，可反复回看，复习巩固相关知识点</div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/bbf525e.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">直播授课</div>
          <div class="item-desc">
            线上每周直播，围绕学习重点，作业难点进行讲解
          </div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/f0b6204.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">助教答疑</div>
          <div class="item-desc">
            学习难点一网打尽，早9到晚10助教都会帮你解决疑问
          </div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/695acf3.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">实战作业</div>
          <div class="item-desc">
            每周布置实战练习，老师助教1V1审批，督促学习
          </div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/865c67c.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">专属班级</div>
          <div class="item-desc">
            已班级为单位成立学习小组，打造线上经验分享座谈
          </div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/a24d5ff.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">打卡成长</div>
          <div class="item-desc">激励学习，极客学院背书，展示学习成果</div>
        </div>
        <div class="service-item">
          <div class="item-img-container">
            <img src="@/assets/images/icon/plus/fd35a4f.svg" class="item-img" />
          </div>
          <div class="item-title font-semibold">毕业证书</div>
          <div class="item-desc">毕业后极客学院背书，展示学习成果</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import marked from "marked";
import camps from "@/global/services/camps";
import { mapState } from "vuex";
export default {
  name: "CampsDetail",
  data() {
    return {
      plusId: "",
      plusList: "",
      projects: "",
      projectLen: "",
      taskLen: "",
      skillLen: 0,
      itemLength: 0,
      dialogVisible: false,
      pagecontent: "",
      activeProjectIndex: 0,
      activeProject: "",
      skillList: ""
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    this.plusId = this.$route.params.id;
    this.getItem(this.plusId);
  },
  methods: {
    changeActiveItem(index) {
      this.activeProjectIndex = index;
      this.getActiveProject();
    },
    JumpProject(content_type, itemId) {
      if (content_type == "page") {
        this.dialogVisible = true;
        camps
          .getCampsItem({ listId: this.plusId, itemId: itemId })
          .then(res => {
            this.pagecontent = marked(res.data.content.html);
          });
      } else {
        window.open(`${process.env.VUE_APP_LEARN}/project/${itemId}`, "_blank");
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    getItem(plusId) {
      camps.getCampsList(plusId).then(res => {
        this.plusList = res.data;
        this.itemLength = res.data.contents.length;

        let projects = this.plusList.contents.filter(item => {
          return item.content_type === "project";
        });
        this.projects = projects;
        this.projectLen = projects.length;
        projects.map(item => {
          return (this.skillLen += Number(item.project.aim_skills.length));
        });
        this.getActiveProject();
      });
    },
    getActiveProject() {
      this.activeProject = this.projects[this.activeProjectIndex].project;
      this.skillList = this.projects[this.activeProjectIndex].project.aim_skills
        .map(item => {
          return item.name;
        })
        .join("、");
    }
  }
};
</script>

<style lang="less" scoped>
@green: #0fc700;
.plus-detail-page {
  min-width: 1240px;
  section[class$="-section"] {
    width: 100%;
  }
  .banner-section {
    height: 560px;
    padding: 20px calc(50% - 620px) 50px;
    background: url(../../../../src/assets/images/common/banner/banner-plusDetail.png)
      no-repeat;
    background-size: cover;
    background-position: center;
    /deep/.el-breadcrumb {
      margin: 0 0 20px;
      .el-breadcrumb__inner {
        &,
        &.is-link {
          color: #fff !important;
          font-weight: 500;
        }
      }
    }
    .banner-content {
      display: flex;
      justify-content: center;
      .banner-card {
        width: 315px;
        padding: 30px;
        font-size: 14px;
        color: #333;
        background: #fff;
        .card-hd {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 20px;
          margin-bottom: 30px;
        }
        .card-bd {
          border-bottom: 1px solid hsla(0, 0%, 59.2%, 0.2);
          .card-title {
            margin-bottom: 5px;
            line-height: 20px;
          }
          .card-desc {
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            word-break: break-all;
            overflow: hidden;
            margin-bottom: 30px;
            &:hover .tooltiptext {
              visibility: visible;
            }
            .tooltiptext {
              position: absolute;
              visibility: hidden;
              width: 600px;
              background-color: #333;
              color: #fff;
              text-align: justify;
              padding: 15px;
              border-radius: 6px;
              z-index: 1;
            }
          }
        }
        .card-ft {
          padding-top: 14px;
          .card-btn {
            display: inline-block;
            width: 100%;
            background: #333;
            color: #fff;
            font-size: 12px;
            line-height: 42px;
            text-align: center;
            border-radius: 0;
          }
        }
      }
      .banner-introduction {
        padding: 25px 0 0 56px;
        width: 925px;
        color: #fff;
        font-size: 16px;
        letter-spacing: 1px;
        .banner-title {
          height: 90px;
          font-size: 64px;
          line-height: 90px;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .banner-desc {
          margin-bottom: 17px;
          line-height: 22px;
          span {
            color: @green;
          }
        }
        .information-container {
          display: flex;
          align-items: center;
        }
        .information-item {
          position: relative;
          margin-right: 60px;
          .information-title {
            height: 48px;
            font-size: 36px;
            line-height: 50px;
          }
          .information-desc {
            line-height: 20px;
            font-size: 14px;
            color: #e2e2e2;
          }
        }
      }
    }
  }
  .project-section {
    padding: 70px calc(50% - 620px) 90px;
    .project-list {
      width: 935px;
      display: flex;
      margin: 62px auto 0;
      align-items: center;
      flex-wrap: wrap;

      .project-item {
        width: 305px;
        height: 190px;
        flex: none;
        cursor: pointer;
        margin: 0 10px 25px 0;
        padding: 23px 45px 36px 30px;
        background: #fff;
        clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
        &:nth-child(3n) {
          margin-right: 0;
        }
        font-weight: 500;
        letter-spacing: 1px;
        .project-item-subtitle {
          line-height: 17px;
          margin-bottom: 20px;
          font-size: 12px;
          color: #666;
          .subtitle-span {
            font-size: 15px;
            color: @green;
          }
        }
        .project-item-title {
          line-height: 46px;
          font-size: 32px;
          color: #252222;
          vertical-align: middle;
          margin-bottom: 10px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .introduction-section {
    background: #fff;
    padding: 58px calc(50% - 620px) 0;
    color: #333;
    .introduction-table {
      background: #f5f5f5;
      display: flex;
      border-top: 1px solid #eaeaee;
      margin-top: 34px;
      .nav-item {
        display: block;
        width: 300px;
        height: 96px;
        background: #fff;
        line-height: 96px;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid #eaeaee;
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.active {
          background: @green;
          color: #fff;
        }
      }
      .table-container {
        flex: 1 1;
        border-left: 1px solid #eaeaee;
        .table-title {
          height: 96px;
          line-height: 96px;
          font-size: 18px;
          text-align: center;
          border-bottom: 1px solid #eaeaee;
        }
        .table-item {
          display: flex;
          .item-th {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 293px;
            color: #4c4c4c;
            font-weight: 500;
            border-bottom: 1px solid #eaeaee;
            border-right: 1px solid #eaeaee;
          }
          .item-td {
            text-align: justify;
            flex: 1 1;
            padding: 30px 47px 30px 78px;
            border-bottom: 1px solid #eaeaee;
          }
        }
      }
    }
  }
  .service-section {
    padding: 110px calc(50% - 620px) 70px;
    .service-list {
      width: 1090px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .service-item {
        width: 224px;
        margin-bottom: 65px;
        flex: none;
        text-align: center;
        font-size: 0;
        .item-title {
          margin: 22px 0 10px;
          height: 28px;
          line-height: 28px;
          color: #333;
          font-size: 20px;
        }
        .item-desc {
          height: 44px;
          font-size: 16px;
          color: #848586;
          line-height: 22px;
          text-align: center;
          width: 224px;
        }
      }
    }
  }
  .section-title {
    width: 100%;
    display: inline-block;
    line-height: 45px;
    font-size: 32px;
    text-align: center;
    margin: 0 auto 10px;
  }
  /deep/.page-item {
    .el-dialog__body {
      video {
        width: 100% !important;
      }
    }
  }
}
</style>
