<template>
  <div class="banner-section">
    <div class="container container-w1200">
      <div class="banner-img">
        <img src="@/assets/images/vip/banner-bg.png" />
      </div>
      <div class="banner-title">
        零基础，轻松入门编程
      </div>
      <div class="banner-sub-title">
        编程就像旅行，不能带你走遍全世界，但能带你走出内心的孤寂。
      </div>
      <div class="banner-desc">
        养成每天有学习、有进步的习惯，无论你是新入职场，还是在职提升，<br />通过我们提供的内容和服务，刻意训练、持续进步、成就自我。
      </div>
      <div class="feature-list">
        <div class="feature-item">
          <div class="item-num">
            最新
          </div>
          <div class="item-desc">
            内容实时更新
          </div>
        </div>
        <div class="feature-item">
          <div class="item-num">
            最全
          </div>
          <div class="item-desc">
            渐进学习体系
          </div>
        </div>
        <div class="feature-item">
          <div class="item-num">
            最真
          </div>
          <div class="item-desc">
            企业项目拆解
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {}
};
</script>

<style lang="less" scoped>
.banner-section {
  padding: 110px 0;
  .container {
    position: relative;
    .banner-img {
      position: absolute;
      top: -82px;
      right: 0;
      width: 628px;
      height: 450px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner-title {
      height: 84px;
      margin-bottom: 16px;
      font-size: 60px;
      line-height: 84px;
      font-weight: 600;
      color: #333333;
    }
    .banner-sub-title {
      height: 25px;
      margin-bottom: 31px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
    }
    .banner-desc {
      width: 557px;
      margin-bottom: 31px;
      font-size: 16px;
      color: #333333;
      line-height: 30px;
    }
    .feature-list {
      position: absolute;
      top: 277px;
      left: 0;
      display: flex;
      align-items: center;
      .feature-item {
        margin-right: 50px;
        .item-num {
          height: 45px;
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          line-height: 45px;
        }
        .item-desc {
          height: 22px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
