import Home from "../views/Home/Home.vue";
import Course from "../views/Course/Course.vue";
import Camps from "../views/Camps/Camps.vue";
import Exam from "../views/Exam/Exam.vue";
import CourseDetail from "../views/Course/widgets/CourseDetail.vue";
import CampsDetail from "../views/Camps/widgets/CampsDetail.vue";
import Setting from "../views/Setting/Setting.vue";
import BasicLayout from "@/components/TheLayout/BasicLayout";
import My from "../views/Setting/widgets/My.vue";
import Currency from "../views/Setting/widgets/Currency.vue";
import Security from "@/views/Setting/widgets/Security.vue";
import Study from "../views/StudyHistory/Study.vue";
import MyEvaluation from "@/views/StudyHistory/widgets/MyEvaluation.vue";
import MyCourse from "@/views/StudyHistory/widgets/MyCourse.vue";

export default [
  {
    path: "/",
    component: BasicLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home
      },
      {
        path: "/course",
        name: "Course",
        component: Course
      },
      {
        path: "/plus",
        name: "Camps",
        component: Camps
      },
      {
        path: "/evaluation",
        name: "Exam",
        component: Exam
      },
      {
        path: "/course/:id",
        name: "CourseDetail",
        component: CourseDetail
      },
      {
        path: "/plus/:id",
        name: "CampsDetail",
        component: CampsDetail
      },
      {
        path: "/search",
        name: "Search",
        component: () => import("../views/Course/Course.vue")
      },
      {
        path: "/plan/:id",
        name: "PlanItem",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Plan/PlanItem.vue")
      },
      {
        path: "/setting",
        component: Setting,
        children: [
          {
            path: "my",
            component: My,
            meta: {
              name: "个人资料"
            }
          },
          {
            path: "currency",
            component: Currency
          },
          {
            path: "security",
            component: Security,
            meta: {
              name: "账号安全"
            }
          }
        ]
      },
      {
        path: "/studyHistory",
        component: Study,
        children: [
          {
            path: "my/course",
            component: MyCourse,
            meta: {
              name: "我的课程"
            }
          },
          {
            path: "my/evaluation",
            component: MyEvaluation,
            meta: {
              name: "我的测评"
            }
          }
        ]
      }
    ]
  }
];
