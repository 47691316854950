<template>
  <el-dialog width="1050px" :visible.sync="dialogVisible" @close="handleClose">
    <div slot="title" class="dialog-header">
      <img :src="item.image_url" />
      <div class="stack-info">
        <span class="title">{{ `${item.name} 技能测评` }}</span>
        <span class="header-desc"
          >想知道你的技能水平是普通还是超群？只需<span>2</span>步就能获取测评结果：</span
        >
      </div>
    </div>
    <div class="dialog-body">
      <div class="dialog-left">
        <div class="step-1">
          <div class="step-left">
            <div class="step-tag">
              <div class="step-number disable-n">
                <i
                  ><svg
                    t="1619008268142"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                  >
                    <path
                      d="M512 0C228.430769 0 0 228.430769 0 512s228.430769 512 512 512 512-228.430769 512-512S795.569231 0 512 0z m256 413.538462l-271.753846 271.753846c-7.876923 7.876923-19.692308 11.815385-31.507692 11.815384-11.815385 0-23.630769-3.938462-31.507693-11.815384l-169.353846-169.353846c-15.753846-15.753846-15.753846-47.261538 0-63.015385 15.753846-15.753846 47.261538-15.753846 63.015385 0l137.846154 137.846154 240.246153-240.246154c15.753846-15.753846 47.261538-15.753846 63.015385 0 19.692308 15.753846 19.692308 47.261538 0 63.015385z"
                      p-id="2127"
                      fill="#0fc700"
                    ></path></svg
                ></i>
              </div>
              <div class="step-title selected">自我评价</div>
            </div>
            <div class="step-path short"></div>
            <div class="step-tag">
              <div class="step-number">2</div>
              <div class="step-title">答题测评</div>
            </div>
          </div>
          <div class="step-right">
            <div class="level-slider">
              <span class="selected">已选择:</span>
              <el-slider
                v-model="value"
                :step="1"
                :min="0"
                :max="5"
                :show-tooltip="false"
                :marks="marks"
                show-stops
              >
              </el-slider>
            </div>
            <div class="step-2">
              <div class="exlanation-text">
                大概25单选题，总共需要15分钟，有一些特别注意事项：
              </div>
              <div class="tags">
                <div class="card-container">
                  <div class="title">
                    <div class="text-title">
                      <i
                        ><svg
                          t="1619013498939"
                          class="icon"
                          viewBox="0 0 1025 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M512 0c282.77 0 512 229.23 512 512s-229.23 512-512 512S0 794.77 0 512 229.23 0 512 0z m1 204c-24.853 0-45 20.147-45 45v254c0 14.767 7.113 27.874 18.1 36.079a45.455 45.455 0 0 0 4.278 4.938l179.605 179.605c17.573 17.574 46.066 17.574 63.64 0 17.573-17.573 17.573-46.066 0-63.64L558 484.36V249c0-24.604-19.746-44.597-44.256-44.994z"
                            fill="#0fc700"
                            p-id="46426"
                          ></path></svg
                      ></i>
                      答题倒计时
                    </div>
                  </div>
                  <div class="content">
                    <span class="text-normal">每道题需要在指定时间内完成，</span
                    ><span class="text-emphasis">超出时间未作答</span
                    ><span class="text-normal">将自动判断为错</span>
                  </div>
                </div>
                <div class="card-container">
                  <div class="title">
                    <div class="text-title">
                      <i
                        ><svg
                          t="1619012545953"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="10531"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M608 0c84.8 0 154.24 61.632 159.68 139.52l0.32 9.792v149.376c0 16.448-14.336 29.824-32 29.824a31.488 31.488 0 0 1-31.488-24.448L704 298.688V149.312c0-46.528-38.08-84.8-86.784-89.152L608 59.712H242.88l216.512 40.192a64 64 0 0 1 52.352 62.912l-0.064 673.408H608c49.92 0 90.88-35.52 95.552-80.96l0.448-8.576V597.312c0-16.448 14.336-29.824 32-29.824 15.68 0 28.8 10.56 31.488 24.448l0.512 5.376v149.376c0 79.168-66.048 143.936-149.504 148.992L608 896H511.68v41.344a64 64 0 0 1-81.728 61.44L110.208 906.24A64 64 0 0 1 64 844.8V149.248C64 70.144 130.048 5.376 213.504 0.32L224 0h384z m274.176 317.888l4.48 3.712 116.352 116.352c21.568 21.568 23.04 55.552 4.608 78.912l-4.608 5.12-116.352 116.416a32 32 0 0 1-48.96-40.832l3.648-4.48L922.496 512H608a32 32 0 0 1 0-64h314.496l-81.152-81.152a32 32 0 0 1-3.648-40.832l3.648-4.416a32 32 0 0 1 40.832-3.712z"
                            p-id="10532"
                            fill="#0fc700"
                          ></path></svg
                      ></i>
                      中途退出
                    </div>
                  </div>
                  <div class="content">
                    <span class="text-normal">中途退出不影响结果，</span
                    ><span class="text-emphasis">超过24小时</span
                    ><span class="text-normal"
                      >仍未完成测评时您需要重新开始测评</span
                    >
                  </div>
                </div>
                <div class="card-container">
                  <div class="title">
                    <div class="text-title">
                      <i
                        ><svg
                          t="1619012840707"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="22924"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M607.232 896a32 32 0 1 1 0 64H192a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h544a96 96 0 0 1 96 96 32 32 0 0 1-64 0 32 32 0 0 0-32-32H192a32 32 0 0 0-32 32v704a32 32 0 0 0 32 32h415.232zM832 585.76v146.656c0 42.656-64 42.656-64 0v-146.656c0-42.688 64-42.688 64 0z"
                            fill="#0fc700"
                          ></path>
                          <path
                            d="M288 320a32 32 0 1 1 0-64h224a32 32 0 0 1 0 64H288zM288 480a32 32 0 0 1 0-64h128a32 32 0 0 1 0 64H288zM639.424 926.592a32 32 0 1 1-64 0L576 800a96 96 0 0 1 96-96l127.744 1.152a32 32 0 1 1 0 64L672 768a32 32 0 0 0-32 32l-0.576 126.592z"
                            p-id="22926"
                            fill="#0fc700"
                          ></path>
                          <path
                            d="M637.92 947.648a32 32 0 1 1-44.32-46.208l180.384-172.992a32 32 0 1 1 44.288 46.176l-180.352 173.024zM880.896 334.528a31.424 31.424 0 0 0 3.328-44.416l-0.416-0.512a32 32 0 0 0-44.896-3.36L408.32 652.288l-15.2 51.84 58.336-4.544 429.44-365.056z m51.616-86.4a95.36 95.36 0 0 1-9.408 134.496l-438.08 372.448a32 32 0 0 1-18.24 7.52l-114.944 8.896a32 32 0 0 1-33.184-40.928l30.944-105.472a32 32 0 0 1 9.984-15.36L797.408 237.504a96 96 0 0 1 134.688 10.112l0.416 0.512z"
                            p-id="22927"
                            fill="#0fc700"
                          ></path></svg
                      ></i>
                      再测一次
                    </div>
                  </div>
                  <div class="content">
                    <span class="text-normal">第一次测评结果不满意时，</span
                    ><span class="text-emphasis">30分钟内可以再测一次</span
                    ><span class="text-normal">，最终将保留分数最高的结果</span>
                  </div>
                </div>
                <div class="card-container">
                  <div class="title">
                    <div class="text-title">
                      <i
                        ><svg
                          t="1619013034232"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="43996"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M673.33 468.52h125.6v63.31h-125.6zM449.71 468.52h127.64v63.31H449.71zM673.33 597.18h125.6v62.29h-125.6zM449.71 597.18h127.64v62.29H449.71zM673.33 722.77h125.6v65.35h-125.6zM225.08 722.77h128.66v65.35H225.08z"
                            p-id="43997"
                            fill="#0fc700"
                          ></path>
                          <path
                            d="M899.12 121h-91.38v128.4c0 14.55-13.72 25.32-29.94 25.95s-29.94-12.35-29.94-25.97V121H276.14v128.17c0 14.51-13.72 25.26-29.94 25.89s-29.94-12.35-29.94-25.89V121h-91.38A59.88 59.88 0 0 0 65 180.86v689.82a59.88 59.88 0 0 0 59.88 59.88h774.24A59.88 59.88 0 0 0 959 870.68V180.86A59.88 59.88 0 0 0 899.12 121z m3.33 739.54a14.22 14.22 0 0 1-13.94 14h-753a14.22 14.22 0 0 1-14-14V368.25h780.9z"
                            p-id="43998"
                            fill="#0fc700"
                          ></path>
                          <path
                            d="M449.71 722.77h127.64v65.35H449.71zM225.08 468.52h128.66v63.31H225.08zM225.08 597.18h128.66v62.29H225.08z"
                            p-id="43999"
                            fill="#0fc700"
                          ></path>
                          <path
                            d="M246.2 255.51m-44.91 0a44.91 44.91 0 1 0 89.82 0 44.91 44.91 0 1 0-89.82 0Z"
                            fill="#0fc700"
                            p-id="44000"
                          ></path>
                          <path
                            d="M777.8 255.51m-44.91 0a44.91 44.91 0 1 0 89.82 0 44.91 44.91 0 1 0-89.82 0Z"
                            fill="#0fc700"
                          ></path>
                          <path
                            d="M270.2 243.14V93.44c0-12.55-11-24.58-24-24s-24 10.55-24 24v149.7c0 12.56 11 24.58 24 24s24-10.54 24-24zM801.8 243.14V93.44c0-12.55-11-24.58-24-24s-24 10.55-24 24v149.7c0 12.56 11 24.58 24 24s24-10.54 24-24z"
                            p-id="44002"
                            fill="#0fc700"
                          ></path></svg
                      ></i>
                      间隔30天
                    </div>
                  </div>
                  <div class="content">
                    <span class="text-normal">测评结果保留最高分后，</span
                    ><span class="text-emphasis">30天以后才能再次测评</span
                    ><span class="text-normal">，请认真对待每一次测评</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="endings">
              <div class="endings-explanation">
                都了解清楚了？来，获取你的技能勋章，现在就开始吧！
              </div>
              <div class="buttons">
                <el-button type="primary" @click="startExam(item.id)">
                  开始答题
                  <i
                    ><svg
                      t="1619013838749"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                    >
                      <path
                        d="M512 48c256.26 0 464 207.74 464 464S768.26 976 512 976 48 768.26 48 512 255.74 48 512 48z m0 64c-220.914 0-400 179.086-400 400s179.086 400 400 400 400-179.086 400-400-179.086-400-400-400z m64.87 203.641l0.378 0.36 0.377 0.372L750.29 489.038a31.921 31.921 0 0 1 6.656 9.703 31.826 31.826 0 0 1 2.62 10.422l0.04 0.568 0.037 0.8 0.02 0.633v0.501c0 0.425-0.009 0.848-0.025 1.27l-0.033 0.662-0.04 0.574a31.847 31.847 0 0 1-8.254 19.054l-0.25 0.27-0.4 0.42-0.37 0.378-172.666 172.665c-12.497 12.496-32.758 12.496-45.255 0-12.372-12.372-12.495-32.354-0.37-44.878l0.37-0.377 118.037-118.038H296c-17.673 0-32-14.327-32-32 0-17.496 14.042-31.713 31.47-31.996l0.53-0.004h354.408L532.37 361.627c-12.497-12.496-12.497-32.758 0-45.254 12.248-12.249 31.955-12.492 44.5-0.732z"
                        p-id="48105"
                        fill="#ffffff"
                      ></path></svg
                  ></i>
                </el-button>
                <div class="exam-later" @click="handleClose">不想测了</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-right">
        <div class="right-header">
          <i>
            <svg
              t="1619015109626"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="51839"
              width="64"
              height="64"
            >
              <path
                d="M512 863.573333c-13.653333 0-23.893333-3.413333-34.133333-10.24-68.266667-44.373333-153.6-61.44-235.52-47.786666-20.48 3.413333-40.96-10.24-40.96-34.133334V242.346667c0-20.48 13.653333-34.133333 34.133333-34.133334h95.573333c30.72 0 58.026667 3.413333 85.333334 10.24 17.066667 3.413333 27.306667 20.48 23.893333 40.96-3.413333 20.48-23.893333 30.72-44.373333 23.893334-20.48-6.826667-54.613333-6.826667-75.093334-6.826667H266.24v457.386667c85.333333-6.826667 174.08 13.653333 245.76 61.44 71.68-47.786667 157.013333-68.266667 245.76-61.44V276.48c-75.093333-6.826667-150.186667 10.24-211.626667 51.2v331.093333c0 20.48-13.653333 34.133333-34.133333 34.133334s-34.133333-13.653333-34.133333-34.133334V310.613333c0-10.24 3.413333-20.48 13.653333-27.306666 88.746667-64.853333 197.973333-92.16 307.2-68.266667 17.066667 3.413333 27.306667 17.066667 27.306667 34.133333v522.24c0 20.48-20.48 37.546667-40.96 34.133334-81.92-17.066667-167.253333 0-235.52 47.786666-13.653333 3.413333-23.893333 10.24-37.546667 10.24z"
                fill="#0fc700"
                p-id="51840"
              ></path>
            </svg>
          </i>
          <div class="examine-instruct">本测评或将覆盖以下知识点</div>
        </div>
        <div class="right-body"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import cookies from "js-cookie";
import { mapState } from "vuex";
import evaluation from "@/global/services/evaluation.js";
export default {
  name: "Dialog",
  props: {
    item: Object,
    visible: Boolean
  },
  data() {
    return {
      dialogVisible: false,
      value: 0,
      marks: {
        1: { label: this.$createElement("strong", "了解") },
        2: { label: this.$createElement("strong", "熟悉") },
        3: { label: this.$createElement("strong", "掌握") },
        4: { label: this.$createElement("strong", "精通") },
        5: { label: this.$createElement("strong", "专家") }
      }
    };
  },
  watch: {
    visible: function(newVal) {
      this.dialogVisible = newVal;
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    handleClose() {
      this.$emit("dialogClose");
    },
    startExam(id) {
      const TOKEN = cookies.get("web_token");
      if (TOKEN) {
        let date = new Date();
        let currentDate = date.toLocaleString();
        if (this.userInfo.vip_expired > currentDate) {
          evaluation.postPaper({ stack_id: id }).then(res => {
            this.paperId = res.data.id;
            window.location.href = `${process.env.VUE_APP_LEARN}/exam/${this.paperId}`;
          });
        } else {
          this.$message.error("请联系管理员获取VIP权限");
        }
      } else {
        window.location.href = `https://account${process.env.VUE_APP_DOMAIN}?referer=${window.location.href}`;
      }
    }
  }
};
</script>

<style lang="less">
@green: #0fc700;
.dialog-body {
  display: flex;
  padding: 0 60px;
  .dialog-left {
    margin-right: 34px;
    .step-1 {
      display: flex;
      .step-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 84px;
        position: relative;
        top: 5px;
        .step-tag {
          width: 48px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .step-number {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid @green;
            font-size: 28px;
            font-family: DINPro-Bold, DINPro;
            font-weight: 700;
            color: @green;
            line-height: 38px;
            text-align: center;
            &.disable-n {
              width: 22.5px;
              height: 22.5px;
              border: 1px solid transparent;
              color: transparent;
              font-size: 14px;
              font-family: DINPro-Bold, DINPro;
              font-weight: 700;
              line-height: 22px;
            }
          }
          .step-title {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
            line-height: 17px;
            margin: 7px 0;
            &.selected {
              color: @green;
            }
          }
        }
        .step-path {
          width: 0;
          border: 1px dotted @green;
          &.short {
            height: 20px;
            margin-bottom: 7px;
          }
        }
      }
      .step-right {
        width: 576px;
        margin-left: 6px;
        .level-slider {
          height: 75px;
          padding-top: 25px;
          width: 100%;
          position: relative;
          display: flex;
          &::after {
            content: " ";
            width: 576px;
            height: 1px;
            background-color: #eee;
            position: absolute;
            bottom: 0;
          }
          .selected {
            width: 64px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999;
          }
          .el-slider {
            flex: 1;
            .el-slider__marks-text {
              margin-top: 0;
              top: -35px;
              &:last-child {
                width: 28px;
                left: 98% !important;
              }
            }
          }
        }
        .step-2 {
          color: #333;
          font-weight: 400;
          font-size: 16px;
          margin-top: 25px;
          .tags {
            display: flex;
            justify-content: space-between;
            margin: 22px 0 37px;
            .card-container {
              width: 120px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #999;
              line-height: 22px;
              .title {
                font-weight: 500;
                color: #333;
                line-height: 32px;
                height: 32px;
                margin-bottom: 4px;
                text-align: start;
                .text-title {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  i {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    display: inline-block;
                  }
                }
              }
              .content {
                .text-emphasis {
                  color: @green;
                }
              }
            }
          }
        }
        .endings {
          .endings-explanation {
            margin-bottom: 5px;
            height: 21px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999;
            line-height: 21px;
          }
          .buttons {
            display: flex;
            .el-button {
              width: 150px;
              height: 40px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #fff;
              border-radius: 1px;
              margin-right: 34px;
              span {
                line-height: 18px;
                i {
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  vertical-align: bottom;
                }
              }
            }
            .exam-later {
              height: 40px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999;
              line-height: 40px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .dialog-right {
    width: 238px;
    padding-left: 28px;
    position: relative;
    top: -110px;
    height: 470px;
    .right-header {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999;
      line-height: 24px;
      i {
        display: block;
        width: 64px;
        height: 64px;
      }
      .examine-instruct {
        width: 210px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      background: #eee;
      left: 0;
    }
  }
}
</style>
